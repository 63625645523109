import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  apiBankDataSetPrimary,
  apiGetBankDataFromBIC,
  apiGetBankDataFromIBAN,
} from "../../../utils/queries";

import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useCallback } from "react";
import {
  inputPattern,
  isNullOrEmpty,
  paymentTypes,
  setMaxLength,
} from "../../../utils/constants";
import IbanForm from "./BankData/IbanForm";
import SwiftForm from "./BankData/SwiftForm";
import FikFrom from "./BankData/FikFrom";

export const BankDataEditModal = (props) => {
  // Priority order for currencies
  const priorityOrder = ["USD", "SGD", "EUR", "DKK"];
  const beneficiaryPattern = /[^a-zA-Z0-9 ()]/g;
  const defaultCurrency = props.isFinanceAccount
    ? priorityOrder[3]
    : priorityOrder[0];
  const accountNumberLength = 10;
  const [isIBAN, setIsIBAN] = useState(props.isFinanceAccount ? false : true);
  const [keepThisOnly, setKeepThisOnly] = useState(false);
  const [isAccountNrValid, setIsAccountNrValid] = useState(true);
  const [isRegNoValid, setIsRegNoValid] = useState(true);
  const [isBICValid, setIsBICValid] = useState(true);
  const [isCorrespondingBICValid, setIsCorrespondingBICValid] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [countryCode, setCountryCode] = useState({});
  const [regNo, setRegNo] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [fikNumber, setFikNumber] = useState("");
  const [fikKortart, setFikKortart] = useState("");
  const [paymentTypeCheckbox, setPaymentTypeCheckbox] = useState(
    props.isFinanceAccount ? paymentTypes.SWIFT : paymentTypes.IBAN
  );
  const [showRegNo, setShowRegNo] = useState(props.isFinanceAccount);

  const toggle = () => {
    setEnableSubmit(false);
    setValue("fikNumber", "");
    setValue("beneficiaryBankAccountName", "");
    setFikNumber("");
    resetRegNo();
    reset();

    if (props.toggle) {
      setEnableSubmit(true);
    }

    props.setToggle(!props.toggle);
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    setValue,
    getValues,
    reset,
    setError,
    clearError,
  } = useForm({
    defaultValues: {
      isIBAN: true,
    },
  });

  const resetRegNo = () => {
    setValue("regNo", "");
    setRegNo(null);
    if (!props.isFinanceAccount) {
      setShowRegNo(false);
    }
  };

  const validateForm = (bankData) => {
    if (
      paymentTypeCheckbox === paymentTypes.IBAN &&
      isNullOrEmpty(bankData.accountNr)
    ) {
      setError("iban", "IBAN is required");
      setError("accountNr", "IBAN is required");
      return false;
    }
    if (
      paymentTypeCheckbox === paymentTypes.SWIFT &&
      isNullOrEmpty(bankData.accountNr)
    ) {
      setError("accountNr", "IBAN is required");
      setError("iban", "BIC/SWIFT is required");
      return false;
    }
    if (showRegNo && (regNo == null || regNo.trim() === "")) {
      setIsRegNoValid(false);
      return false;
    }
    if (showRegNo && bankData.accountNr?.length < accountNumberLength) {
      setError(
        "accountNr",
        `Account Number must have ${accountNumberLength} characters`
      );
      return false;
    }

    if (showRegNo && (bankData?.regNo?.length !== 4 || regNo?.length !== 4)) {
      setError("regNo", `Registration number requires 4 characters`);
      return false;
    }

    if (beneficiaryPattern.test(bankData.beneficiaryBankAccountName)) {
      setError(
        "beneficiaryBankAccountName",
        `Only alphanumeric characters Abc-Xyz or 0-9 and special character () are allowed by bank(s).`
      );
      return false;
    }

    if (paymentTypeCheckbox === paymentTypes.FIK) {
      const firstNumber = bankData.fikNumber?.substring(0, 1);
      if (bankData.fikNumber?.length <= 6) {
        setError("fikNumber", `A minimum of 7 characters is required.`);
        console.log("fifth");
        return false;
      }
      if (bankData.fikNumber?.length === 7 && firstNumber !== 0) {
        setFikNumber(`0${fikNumber}`);
        bankData.fikNumber = `+0${fikNumber}`;
      } else {
        bankData.fikNumber = `+${fikNumber}`;
      }
    }
    return true;
  };

  const onSubmit = async (bankData) => {
    if (validateForm(bankData)) {
      if (paymentTypeCheckbox !== paymentTypes.FIK) {
        bankData.countryCode = countryCode;
      }
      bankData.regNo = regNo;
      bankData.type = paymentTypeCheckbox;
      bankData.beneficiaryBankAccountName = beneficiaryName;
      bankData.beneficiaryBankAccountName =
        bankData.beneficiaryBankAccountName.trim();
      resetRegNo();
      props.onSubmit(bankData);
      setCountryCode(priorityOrder[0]);
      return;
    }

    props.addAlert("Not all required fields are filled", "danger");
  };

  const resetMetadata = async (event) => {
    setEnableSubmit(false);
    setIsBICValid(true);
    setIsCorrespondingBICValid(true);

    if (paymentTypeCheckbox === paymentTypes.IBAN) {
      setValue("bic", "");
    }

    setValue("bank", "");
    setValue("branch", "");
    setValue("address", "");
    setValue("city", "");
    setValue("zip", "");
    setValue("country", "");
  };

  const onGetIBAN = async (event) => {
    try {
      let values = getValues();
      if (values.accountNr == null || values.accountNr.trim() === "") {
        setIsAccountNrValid(false);
        return;
      }
      if (showRegNo && (values.regNo == null || values.regNo.trim() === "")) {
        setIsRegNoValid(false);
        return;
      }
      var result = await apiGetBankDataFromIBAN(values.accountNr, props.token);
      setIsAccountNrValid(result.data.isIBANValid);
      setEnableSubmit(result.data.isIBANValid);
      setIsRegNoValid(true);
      setValue("bic", result.data.bankData.bic);
      setValue("bank", result.data.bankData.bank);
      setValue("branch", result.data.bankData.branch);
      setValue("address", result.data.bankData.address);
      setValue("city", result.data.bankData.city);
      setValue("zip", result.data.bankData.zip);
      setValue("country", result.data.bankData.country);

      setValue("countryCode", result.data.bankData.countryIso);

      setCountryCode(result.data.bankData.countryIso);

      // setValue("currencyId", result.data.bankData.currencyId);
    } catch (error) {
      alert(error);
    }
  };

  const onGetBIC = async (event) => {
    try {
      setIsAccountNrValid(true);
      setIsRegNoValid(true);
      setIsBICValid(true);
      setIsCorrespondingBICValid(true);

      let values = getValues();

      console.log(values);

      if (values.accountNr == null || values.accountNr.trim() === "") {
        setIsAccountNrValid(false);
        return;
      }

      if (showRegNo && (values.regNo == null || values.regNo.trim() === "")) {
        setIsRegNoValid(false);
        return;
      }

      if (values.bic == null || values.bic.trim() === "") {
        setIsBICValid(false);
        return;
      }

      var result = await apiGetBankDataFromBIC(
        values.accountNr,
        values.bic,
        props.token
      );

      let correspondingBICIsValid = true;
      if (
        values.correspondingBic !== null &&
        values.correspondingBic.trim() !== ""
      ) {
        var resultCorresponding = await apiGetBankDataFromBIC(
          values.accountNr,
          values.correspondingBic,
          props.token
        );

        console.log(resultCorresponding);

        if (!resultCorresponding.data.isBICValid) {
          correspondingBICIsValid = false;
          setIsCorrespondingBICValid(false);

          console.log(resultCorresponding.data.isBICValid);

          return;
        }
      }

      var bicdata = result.data.bicdata === null ? {} : result.data.bicdata;

      setIsBICValid(result.data.isBICValid);
      setIsAccountNrValid(result.data.isAccountNrValid);

      // console.log("bicdata");
      setCountryCode(bicdata.isoCountryCode);

      setEnableSubmit(
        result.data.isBICValid &&
          result.data.isAccountNrValid &&
          correspondingBICIsValid
      );

      // console.log(bicdata);

      // setUserInput({"bic": result.data.bankData.bic||''});
      // setUserInput({"bank": bicdata.institutionName || ''});

      setValue("bank", bicdata.institutionName || "");
      //setValue("branch",result.data.bankData.branch||'');
      setValue("address", bicdata.opAddress || "");
      setValue("city", bicdata.opCity || "");
      setValue("zip", bicdata.opZipCode || "");
      setValue("country", bicdata.countryName || "");

      // console.log(result);
    } catch (error) {
      alert(error);
    }
  };

  // Function to sort currencies by predefined priority
  const sortCurrencies = (currencies) => {
    const orderMap = priorityOrder.reduce((acc, currency, index) => {
      acc[currency] = index;
      return acc;
    }, {});
    return currencies.sort((a, b) => {
      return (orderMap[a.name] ?? Infinity) - (orderMap[b.name] ?? Infinity);
    });
  };

  // Get sorted currencies
  const sortedCurrencies = sortCurrencies(props.currencyList);

  // Find the ID of USD for setting as default value if exists
  const defaultCurrencyId = sortedCurrencies.find((currency) =>
    props.isFinanceAccount ? currency.name === "DKK" : currency.name === "USD"
  )?.id;

  const regNoDisplay = useCallback(
    (name) => {
      if (name === "DKK" && props.isFinanceAccount) {
        setShowRegNo(true);
        setPaymentTypeCheckbox(paymentTypes.SWIFT);
        setValue("accountNr", "");
      } else {
        setShowRegNo(false);
        // setPaymentTypeCheckbox(paymentTypes.IBAN);
      }
    },
    [countryCode]
  );

  const handleCurrencyOnChange = (e) => {
    setCurrency(e.target.options[e.target.selectedIndex].text);
    regNoDisplay(e.target.options[e.target.selectedIndex].text);
  };

  const handleOnChangeRegistrationNo = (e) => {
    if (e.target.value?.length < 4) {
      setError("regNo", `Registration number requires 4 characters`);
    }
    const limitedValue = inputPattern({
      pattern: /\D/g,
      length: 4,
      value: e.target.value,
    });
    setRegNo(limitedValue);
    setIsRegNoValid(true);
  };

  const handleKortartChange = (e) => {
    setFikKortart(e.target.options[e.target.selectedIndex].text);
  };

  const handleOnChangeFik = (e) => {
    if (e.target.value <= 6) {
      setError("fikNumber", `A minimum of 8 characters is required.`);
      setEnableSubmit(false);
    }
    if (e.target.value >= 7) {
      clearError("fikNumber");
      setEnableSubmit(true);
    }
    const limitedValue = inputPattern({
      // pattern: /[^\+0-9]/g,
      pattern: /\D/g,
      length: 8,
      value: e.target.value,
    });
    setFikNumber(limitedValue);
  };

  const handleOnChangeAccountNumber = (e) => {
    e.target.value = showRegNo
      ? setMaxLength(accountNumberLength, e.target.value)
      : e.target.value;
    if (e.target.value.length <= 9 && showRegNo) {
      setError(
        "accountNr",
        `Account Number must have ${accountNumberLength} characters`
      );
    }

    if (e.target.value.length === accountNumberLength) {
      clearError("accountNr");
    }
    resetMetadata(e);
  };

  const handleBeneficiaryOnchange = (e) => {
    const value = e.target.value;
    if (beneficiaryPattern.test(value)) {
      setError(
        "beneficiaryBankAccountName",
        `Only alphanumeric characters Abc-Xyz or 0-9 are allowed by bank(s).`
      );
    } else {
      clearError("beneficiaryBankAccountName");
    }
    setBeneficiaryName(value);
  };

  return (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Bank Data</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="keepThisOnly"
                    innerRef={register}
                    checked={keepThisOnly}
                    onChange={() => setKeepThisOnly(!keepThisOnly)}
                  />{" "}
                  This is the only {currency} account to keep
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="type"
                    innerRef={register}
                    checked={paymentTypeCheckbox === paymentTypes.IBAN}
                    onChange={() => {
                      setPaymentTypeCheckbox(paymentTypes.IBAN);
                      setEnableSubmit(false);
                      setShowRegNo(false);
                    }}
                  />{" "}
                  IBAN
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="type"
                    innerRef={register}
                    checked={paymentTypeCheckbox === paymentTypes.SWIFT}
                    onChange={() => {
                      setPaymentTypeCheckbox(paymentTypes.SWIFT);
                      setEnableSubmit(false);
                      if (!props.isFinanceAccount) {
                        setShowRegNo(false);
                      } else {
                        setShowRegNo(true);
                      }
                    }}
                  />{" "}
                  BIC/SWIFT
                </Label>
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="type"
                    innerRef={register}
                    checked={paymentTypeCheckbox === paymentTypes.FIK}
                    onChange={() => {
                      setPaymentTypeCheckbox(paymentTypes.FIK);
                      setEnableSubmit(false);
                      setShowRegNo(false);
                    }}
                  />{" "}
                  FIK
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>
                {" "}
                Beneficiary name (usually same as Vendor name) (Company name)*
              </Label>
              <Input
                type="input"
                name="beneficiaryBankAccountName"
                value={beneficiaryName}
                innerRef={register({ required: true })}
                onChange={(e) => handleBeneficiaryOnchange(e)}
              />
              <div className={"text-danger"}>
                {errors.beneficiaryBankAccountName &&
                  errors.beneficiaryBankAccountName.type}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Currency</Label>
              <select
                ref={register({ required: true })}
                className="form-control"
                name="currencyId"
                defaultValue={defaultCurrencyId}
                onChange={(e) => handleCurrencyOnChange(e)}
              >
                {sortedCurrencies?.map((currency, index) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.name}
                  </option>
                ))}
              </select>
              <FormText color="muted">
                Please note that there can only be filled in one Bank detail for
                each currency. Delete existing if you need to fill a new one.
              </FormText>
            </FormGroup>
            {showRegNo && (
              <FormGroup>
                <Label for="exampleText">Registration Number*</Label>
                <Row>
                  <Col sm="12">
                    <Input
                      type="number"
                      value={regNo}
                      name="regNo"
                      innerRef={register({ required: true })}
                      onChange={handleOnChangeRegistrationNo}
                    />
                  </Col>
                </Row>
                <div className={"text-danger"}>
                  {!isRegNoValid && "This field is required"}
                </div>
                <div className={"text-danger"}>
                  {errors.regNo && errors.regNo.type}
                </div>
              </FormGroup>
            )}
            {paymentTypeCheckbox === paymentTypes.FIK && (
              <FikFrom
                checkbox={paymentTypeCheckbox}
                handleKortartChange={handleKortartChange}
                handleOnChangeFik={handleOnChangeFik}
                isRegNoValid={isRegNoValid}
                register={register}
                fikNumber={fikNumber}
                errors={errors}
              />
            )}
            {paymentTypeCheckbox !== paymentTypes.FIK && (
              <>
                <IbanForm
                  checkbox={paymentTypeCheckbox}
                  onGetIBAN={onGetIBAN}
                  errors={errors}
                  register={register}
                  resetMetadata={resetMetadata}
                  showRegNo={showRegNo}
                  isBICValid={isBICValid}
                  isAccountNrValid={isAccountNrValid}
                  isCorrespondingBICValid={isCorrespondingBICValid}
                  handleAccountNo={handleOnChangeAccountNumber}
                />
                <SwiftForm
                  checkbox={paymentTypeCheckbox}
                  onGetBIC={onGetBIC}
                  errors={errors}
                  register={register}
                />
              </>
            )}
            <br></br>
            <Button
              color={enableSubmit ? "success" : "info"}
              type="submit"
              disabled={!enableSubmit}
            >
              Submit
            </Button>{" "}
            <Button color="warning" onClick={toggle}>
              Cancel
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
